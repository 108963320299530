/*
======================
Footer
======================
*/

.footer {
  margin-top: 6.5vw;

  .r-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &_c-first {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }

    &_c-second {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
  }

  &--email {
    font-size: 6vw;
    font-size: calc(24 * 100vw / var(--size));
    margin-top: 1.25rem;
    margin-bottom: 14.375vw;
    transition: 0.3s ease all;
    color: #fff;

    &:hover {
      color: $primary;
    }
  }

  .w-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5625rem;
    margin-bottom: 6.25rem;
    font-size: calc(12 * 100vw / var(--size));
    font-size: 0.833333vw;

    &--bye {
      display: flex;

      p {
        padding-right: 0.45rem;
        color: #fff;
      }
    }

    &--name {
      color: #fff;
    }
  }
}

.f-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 6.25vw;

  &--1,
  &--2,
  &--3 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;

    a {
      margin-top: 1.25rem;
      font-size: 1.3020833333333335vw;
      font-size: calc(18.5 * 100vw / var(--size));
      text-decoration: underline;
      transition: 0.3s ease all;
      color: #fff;

      &:hover {
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer--email {
    // font-size: 6vw;
    margin-top: 2.5vw;
    font-size: calc(24 * 100vw / var(--size));
    margin-bottom: 14.375vw;
  }

  .f-links {
    margin-bottom: 6.25vw;

    &--1,
    &--2,
    &--3 {
      a {
        margin-top: 2vw;
        // font-size: 1.5vw;
        font-size: calc(24 * 100vw / var(--size));
        text-decoration: underline;
        transition: 0.3s ease all;
        color: #fff;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .footer .w-copyright {
    // margin-bottom: 6.25rem;
    margin-top: 2vw;
    font-size: 1.2vw;
    font-size: calc(17 * 100vw / var(--size));
  }
}

@media screen and (max-width: 890px) {
  .f-links {
    margin-bottom: 80px !important;

    &--1,
    &--2,
    &--3 {
      a {
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }

  .footer--email {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 180px;
  }

  .footer .w-copyright {
    margin-top: 21px;
    font-size: 14px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    margin-top: 0;
  }

  .footer .r-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .r-footer_c-first {
    margin-bottom: 8rem;
  }

  .f-links {
    margin-bottom: 80px !important;

    &--1,
    &--2,
    &--3 {
      flex-wrap: wrap;
      a {
        margin-top: 20px;
        font-size: 18px;
        margin-right: 1rem;
      }
    }
  }

  .footer--email {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 200px;
  }

  .footer .w-copyright {
    margin-top: 21px;
    font-size: 12px;
  }
}

@media screen and (max-width: 580px) {
  .r-footer_c-first {
    margin-bottom: 5rem;
  }

  .footer--email {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 140px;
  }

  .footer .w-copyright {
    margin-bottom: 4rem;
  }
}
