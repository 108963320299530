/*
======================
About
======================
*/

.about {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding-top: 8.15vw;
  padding-bottom: 12.055vw;
  // width: 100%;

  .social_links {
    flex: 1 1 40%;

    a {
      text-decoration: none;
      font-size: calc(21.6 * 100vw / var(--size));
      font-size: 1.5vw;
      color: $black;
      will-change: transform;

      &.darkMode {
        color: #fff;
      }

      &.darkMode:hover {
        color: #fff;
      }

      &::after {
        display: inline-block;
        padding-left: 0.875rem;
        content: '→'; // arrow right unicode
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
      }

      &:hover {
        color: $black;

        &::after {
          -webkit-transform: translateX(4px);
          -moz-transform: translateX(4px);
          -ms-transform: translateX(4px);
          -o-transform: translateX(4px);
          transform: translateX(4px);
        }
      }
    }

    a + a {
      margin-left: 8%;
    }
  }

  .intro {
    flex: 2 1 60%;

    &--short {
      // max-width: 80%;
      font-size: calc(21.6 * 100vw / var(--size));
      font-size: 1.5vw;
      line-height: 1.5;
      margin-bottom: 2vw;

      &.darkMode {
        color: #fff;
      }
    }

    &--long {
      // max-width: 80%;
      font-size: calc(15.8 * 100vw / var(--size));
      font-size: 1.2vw;
      color: $gray;
      line-height: 1.5;
    }
  }

  .social_links + .intro {
    padding-left: 7.2%;
  }
}

@media screen and (max-width: 1280px) {
  .intro {
    &--short {
      line-height: 1.6 !important;
    }

    &--long {
      // max-width: 80%;
      line-height: 1.68 !important;
    }
  }
}

@media screen and (max-width: 996px) {
  .about .social_links a::after {
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 890px) {
  .about .intro--short {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 28px;
  }

  .about .intro--long {
    font-size: 16px;
    line-height: 1.75 !important;
  }

  .about {
    padding-top: 8.15vw;
    padding-bottom: 12.055vw;

    .social_links {
      a {
        display: block;
        font-size: 20px;
        margin-bottom: 0.85rem;

        &::after {
          padding-left: 0.875rem;
        }
      }

      a + a {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about .social_links + .intro {
    padding-left: 0;
  }

  .about {
    padding-top: 140px;
    padding-bottom: 140px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;

    .social_links {
      margin-top: 4rem;

      a + a {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .about {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  .about .intro--short {
    font-size: 18px;
  }

  .about .intro--long {
    font-size: 16px;
  }

  .about .social_links {
    font-size: 18px;
  }
}
