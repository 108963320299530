/*
======================
General
======================
*/

/*
======================
Note to Self:
Whatever it's not on a media query. it's for 1080p or above
======================
*/

/*
======================
Loader
======================
*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li,
a,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  transition: font-size 0.1s ease-out;
}

.box--loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100vw;
  // height: 100vh;
  background: $black;
  color: white;
  z-index: 999;
  animation: 1.2s cubic-bezier(0.9, 0.12, 0.2, 1) 3.8s wipeLoader forwards;
  will-change: transform;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;

  .text-loading-wrap {
    // height: 2.55vw;
    overflow: hidden;
    white-space: nowrap;
    transform: translate3D(0, 0, 0);
    -webkit-transform: translate3D(0, 0, 0);
  }

  .text-loading {
    font-family: $suisse-light;
    font-weight: 400;
    font-size: 1.4583333333333333vw;
    font-size: calc(21 * 100vw / var(--size));
    animation: 1.5s cubic-bezier(0.79, 0.17, 0.28, 1.1) moveTextUp,
      1.5s cubic-bezier(0.79, 0.17, 0.28, 1.1) moveTextUpper 3s forwards;
    line-height: 2.25vw;
    transform: translate3D(0, 0, 0);
    -webkit-transform: translate3D(0, 0, 0);
  }

  &.hide {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes wipeLoader {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes moveTextUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes moveTextUpper {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 890px) {
  .box--loader {
    background: $black;
    color: white;
    z-index: 99;

    .text-loading {
      font-size: 24px;
      line-height: 28px;
    }
  }

  body {
    transition: 0.3s ease all;
  }
}

/* Background Noise Effect */
main {
  scroll-behavior: smooth;
}

.main::before {
  content: '';
  z-index: 9999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/background-noise.png');
  animation: 0.1s infinite noise;
  pointer-events: none;
}

@keyframes noise {
  0%,
  100% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
}

.footer-grid::before {
  content: '';
  z-index: 9999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://uploads-ssl.webflow.com/5a841cafef2b990001bcadd1/5ce40e59c004885ff4348712_background-noise.png);
  animation: 0.1s infinite noise;
  pointer-events: none;
}

@keyframes noise {
  0%,
  100% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
}

.product-slider::before {
  content: '';
  z-index: 9999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://uploads-ssl.webflow.com/5a841cafef2b990001bcadd1/5ce40e59c004885ff4348712_background-noise.png);
  animation: 0.1s infinite noise;
  pointer-events: none;
}

@keyframes noise {
  0%,
  100% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
}

/*Remove Scrollbar on Desktop */
::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: -moz-scrollbars-none;
  /* Firefox */
  display: none;
  /* Safari and Chrome */
}

/* Ink Cursor */
.goo {
  position: absolute;
  top: 0;
}

.ink-cursor {
  pointer-events: none;
  position: fixed;
  display: block;
  border-radius: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  mix-blend-mode: difference;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-filter: url('#goo');
  filter: url('#goo');
}

.ink-cursor span {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background-color: $primary;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  will-change: transform;
}

@media (max-width: 991px) {
  .ink-cursor,
  .ink-cursor span {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  // border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  font-family: $suisse-regular;
}

body {
  background: $whitesmoke !important;
  transition: 0.4s ease all;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  // -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-kerning: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.getRealScreen {
  display: none;
}

.changeColor {
  background: $black !important;
}

a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #292622;
  margin: 1em 0;
  padding: 0;
}

/*
======================
Custom Font
======================
*/

@font-face {
  font-family: 'SuisseIntl-Thin';
  src: url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.eot');
  src: url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.svg#SuisseIntl-Thin')
      format('svg'),
    url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.ttf')
      format('truetype'),
    url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.woff')
      format('woff'),
    url('../../assets/fonts/SuisseIntl-Thin/SuisseIntl-Thin.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl-Light';
  src: url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.eot');
  src: url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.svg#SuisseIntl-Light')
      format('svg'),
    url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.ttf')
      format('truetype'),
    url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.woff')
      format('woff'),
    url('../../assets/fonts/SuisseIntl-Light/SuisseIntl-Light.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.eot');
  src: url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.svg#SuisseIntl-Regular')
      format('svg'),
    url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.ttf')
      format('truetype'),
    url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.woff')
      format('woff'),
    url('../../assets/fonts/SuisseIntl-Regular/SuisseIntl-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl-Book';
  src: url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.eot');
  src: url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.svg#../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book')
      format('svg'),
    url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.ttf')
      format('truetype'),
    url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.woff')
      format('woff'),
    url('../../assets/fonts/SuisseIntl-Book/SuisseIntl-Book.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

/*
======================
Custom Classes
======================
*/

.wrapper {
  // display: flex;
  margin: 0 auto;
  padding: 0 9.375vw;
  padding-left: calc(135 * 100vw / var(--size));
  padding-right: calc(135 * 100vw / var(--size));
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.text-underline {
  text-decoration: underline;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.underline {
  text-decoration: underline;
}

@media screen and (min-width: 890px) {
  // .hide-medium-up {}
  .show-medium-down {
    display: none;
  }
}

@media screen and (max-width: 889px) {
  // .show-medium-up {}
  .hide-medium-up {
    display: none;
  }
}

/*
======================
Headings
======================
*/

.o-heading-l {
  font-family: $suisse-light;
  font-weight: 400;
  font-size: 3.75vw;
  font-size: calc(54 * 100vw / var(--size));
  line-height: 5.25vw;
}

.o-heading-m {
  font-family: $suisse-light;
  font-weight: 400;
  font-size: 3.75vw;
  font-size: calc(42 * 100vw / var(--size));
  color: $black;
  line-height: 4vw;

  span {
    font-family: $suisse-regular;
  }

  &.darkMode {
    color: #fff;
  }
}

@media (min-width: 640px) {
  :root {
    --gutter: 7.35vw;
    --gutter-s: calc(var(--gutter) / 2);
    --size: 1440;
  }
}

@media screen and (min-height: 1200px) and (max-height: 1600px) {
  .o-heading-l {
    font-family: $suisse-light;
    font-weight: 400;
    font-size: 3.75vw;
    font-size: calc(54 * 100vw / var(--size));
    line-height: 5.95vw;
  }
}

@media screen and (max-width: 996px) {
  .o-heading-l {
    line-height: 5.5vw;
  }

  .o-heading-m {
    line-height: 4.5vw !important;
  }
}

@media screen and (max-width: 890px) {
  .o-heading-l {
    font-size: 40px;
    line-height: 1.4;
  }

  .o-heading-m {
    font-size: 28px !important;
    line-height: 1.4 !important;
  }
}

@media screen and (max-width: 768px) {
  .o-heading-m {
    font-size: 31px !important;
    line-height: 1.5 !important;
  }
}

/* Core Marquee */
.marquee-title {
  animation-delay: 1s;
  animation: marqueeSlider 7s infinite linear;
  will-change: transform;
}

.marquee-title:hover {
  animation-play-state: paused;
}

@keyframes marqueeSlider {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.marquee,
.ms-desc {
  left: 0;
  right: 0;
  bottom: 0;
}

._w-item,
.marquee {
  position: relative;
}

.marquee,
.marquee-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.marquee {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.marquee {
  top: 0;
  display: flex;
  overflow: hidden;
  width: 100px;
  height: 38px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: calc(21 * 100vw / var(--size));
}

.marquee-wrap {
  left: 0;
  top: 0;
  -webkit-box-align: center;
}

.marquee-wrap {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.marquee-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.marquee-title {
  display: flex;
}

._w-title {
  position: relative;
  font-size: calc(14 * 100vw / var(--size));
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 1;
  color: $black;

  &.darkMode {
    color: $tan;
  }
}

// Hover Image
.hoverImg {
  display: none;
  max-width: 16vw;
  position: absolute;
  // margin-top: 5rem;
  margin-left: 8%;
  z-index: 99;
}

@media screen and (max-width: 1280px) {
  .marquee {
    width: 65px;
    height: 38px;
  }

  .hero--headline span.underline:after {
    border-width: 0 0 1.25px !important;
  }

  .hoverImg {
    max-width: 30vw;
  }
}

@media screen and (max-width: 890px) {
  ._w-title {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
    color: $black;
  }
}

@media screen and (max-width: 820px) {
  .box--loader {
    .text-loading {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .wrapper {
    padding: 0 9vw;
  }
}

@media screen and (max-width: 580px) {
  .wrapper {
    padding: 0 5.4vw;
  }

  .o-heading-l {
    font-size: 36px;
    line-height: 1.4;
  }

  .o-heading-m {
    font-size: 28px !important;
    line-height: 1.4 !important;
  }
}

@media screen and (max-device-width: 820px) and (orientation: landscape) {
  body {
    background: url('../../assets/background-noise.png') white !important;
  }

  .changeColor {
    background: url('../../assets/background-noise.png') white !important;
  }

  .getRealScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('../../assets/background-noise.png') white;
    animation: 0.1s infinite noise;
    z-index: 9999;

    img {
      display: block;
      margin: 0 auto 1.5rem;
      max-width: 30vw;
      width: 30vw;
      // border: 1px solid black;
    }

    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 1280px) {
  .lazyPortfolio {
    display: none !important;
    visibility: hidden;
    opacity: 0;
  }
}

.lazyPortfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
  position: fixed;
  bottom: 0;
  left: 50%;
  max-width: 70vw;
  transform: translate(-50%, 100%);
  min-width: 70vw;
  background: white;
  padding: 1.35417vw 2.76042vw;
  margin: 0 auto;
  transition: 0.7s ease all;
  transition-delay: 0.5s;

  &.moveUp {
    transform: translate(-50%, 0%);
  }

  &.removed {
    transform: translate(-50%, 100%);
    opacity: 0;
    visibility: hidden;
  }
}

.lazyText {
  font-size: 0.98958vw;
  line-height: 1.35417vw;
  margin-right: 3.64583vw;
}

.lazyOk {
  width: 14.6875vw;
  height: 3.28125vw;
  font-size: 1vw;
  cursor: pointer;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  transition: 0.3s ease all;
  border: none;

  &:hover {
    background: #212121;
  }

  &:focus {
    border: none !important;
  }
}

@media screen and (max-width: 640px) {
  #awwwards {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .hoverImg {
    max-width: 50vw;
  }
}

@media screen and (max-width: 340px) {
  .o-heading-l {
    font-size: 31px;
  }
  .o-heading-m {
    font-size: 24px;
  }
}
