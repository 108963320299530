/*
======================
Navbar
======================
*/

.nav {
  padding: 1.822916vw calc(135 * 100vw / var(--size));
  position: fixed;
  left: 0;
  right: 0;
  // font-size: 1.04167vw;
  font-size: calc(15.8 * 100vw / var(--size));
  font-size: 1.09375vw;
  background: transparent;
  z-index: 999;
  user-select: none;
  // mix-blend-mode: difference;

  &--wrapper {
    display: flex;
    justify-content: space-between;
  }

  &--logo {
    font-family: $suisse-regular;
    color: $black;
    z-index: 99;

    &.darkMode {
      color: #fff;
    }
  }

  &--link {
    display: flex;
    flex-direction: row;
    list-style: none;
    z-index: 999;
    text-decoration: none;

    &-item {
      padding-left: calc(31.5 * 100vw / var(--size));
      padding-left: 2.1875vw;
      // text-transform: uppercase;

      button {
        text-decoration: none;
        color: $black;
        transition: all 0.5s cubic-bezier(0, 1.07, 0.89, 0.99);
        outline: 0;
        border: none;

        &:hover {
          color: $primary;
        }

        &.darkMode {
          color: #fff;
        }

        &.darkMode:hover {
          color: $primary;
        }
      }
    }
  }
}

.hover-effect li {
  overflow: hidden;
  padding: 0 4px;
  height: auto;
  margin-left: calc(31.5 * 100vw / var(--size));
  margin-left: 2.1875vw;
}

.hover-effect li button {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.5s cubic-bezier(0, 1.07, 0.89, 0.99);
  will-change: transform;
}

.hover-effect li button::before {
  position: absolute;
  top: 100%;
  content: attr(data-hover);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hover-effect li:hover button,
.hover-effect li:focus button {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

// burger button
.burger_menu {
  outline: none;
  border: none;
  z-index: 99;

  &-top {
    top: 0;
    width: 24px;
    height: 1px;
    background-color: $black;
    margin: 5px 0;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.is-active {
      transform: translateY(3px) rotate(45deg) translateZ(0);
    }

    &.darkMode {
      background-color: #fff;
    }
  }
  &-bottom {
    bottom: 0;
    width: 24px;
    height: 1px;
    background-color: $black;
    margin: 5px 0;
    transform: translateZ(0);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.is-active {
      transform: translateY(-3px) rotate(-45deg) translateZ(0);
    }

    &.darkMode {
      background-color: #fff;
    }
  }
}

@media screen and (min-width: 821px) {
  .burger_menu {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .nav {
    padding: 2.4vw calc(135 * 100vw / var(--size));
    font-size: 1.4vw;
    font-size: calc(21 * 100vw / var(--size));
  }
}

@media screen and (max-width: 890px) {
  .nav {
    padding: 3.5vw calc(135 * 100vw / var(--size));
    font-size: 17px !important;
    mix-blend-mode: normal !important;

    &--link-item {
      padding-left: calc(35 * 100vw / var(--size));
      padding-left: 2.5vw;
    }
  }

  .hover-effect li {
    overflow: hidden;
    padding: 0 4px;
    height: auto;
    margin-left: 18.5px;
  }
}

@media screen and (max-width: 820px) {
  .nav {
    padding: 3.65vw 9vw;
    font-size: 17px !important;

    &--link-item {
      display: none;
    }
  }
}

/*
======================
Navbar Responsive
======================
*/

.menu_item-wrap {
  // height: 2.55vw;
  overflow: hidden;
  white-space: nowrap;
  transform: translate3D(0, 0, 0);
  -webkit-transform: translate3D(0, 0, 0);
  margin-bottom: 40px;

  &:first-child {
    margin-top: 25%;
  }
}

.menu--responsive {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 640px;
  height: 80vh;
  width: 100vw;
  text-align: center;
  background: white;
  z-index: 20;
  transform: translateY(-105%);
  will-change: transform;

  &.is-active {
    // visibility: visible;
    // opacity: 1;
    animation: 1.5s cubic-bezier(0.9, 0.12, 0.2, 1) moveDownMenu forwards;
  }

  &.removed {
    // visibility: hidden;
    // opacity: 0;
    animation: 1.5s cubic-bezier(0.9, 0.12, 0.2, 1) moveUpMenu forwards;
  }

  &_items {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 72%;
    list-style: none;

    li {
      font-family: $suisse-light;
      font-size: 39px;
      // padding-bottom: 40px;
      overflow: hidden;
      position: relative;
      transform: translateY(100%);

      &.isVisible {
        animation: 1s cubic-bezier(0.165, 0.84, 0.44, 1) moveMenuItemsUp
          forwards;
      }

      &.isHidden {
        animation: 1s cubic-bezier(0.165, 0.84, 0.44, 1) moveMenuItemsDown
          forwards;
      }
    }
  }

  &_divider {
    // max-width: 80%;
    border-top: 1px solid #cccccc;
    width: 65%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    transition: 0.4s ease opacity !important;
    -webkit-transition: -webkit-transform 0.4s ease opacity;
    -webkit-transition: 0.4s ease opacity;

    &.is-visible {
      transition-delay: 1.2s;
      visibility: visible;
      opacity: 1;
    }

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &_footer {
    transition: 0.4s ease opacity !important;
    -webkit-transition: -webkit-transform 0.4s ease opacity;
    -webkit-transition: 0.4s ease opacity;

    h4 {
      font-family: $suisse-light;
      margin-bottom: 1rem;
      color: rgba($color: #000000, $alpha: 0.75) !important;
    }

    .c-mail_menu {
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      line-height: 1.4;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.02em;
        min-height: 1px;
        background: #000;
        transform-origin: right;
      }
    }

    &.is-visible {
      transition-delay: 1.4s;
      visibility: visible;
      opacity: 1;
    }

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.bg_dimming {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1) !important;
  -webkit-transition: opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 19;
  opacity: 0;
  visibility: hidden;
  // transition: 0.4s ease all;

  &.is-active {
    transition-delay: 1s;
    opacity: 0.8;
    visibility: visible;
  }
}

@keyframes moveDownMenu {
  0% {
    transform: translateY(-105%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveUpMenu {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-105%);
  }
}

@keyframes moveMenuItemsUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveMenuItemsDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@media screen and (min-width: 821px) {
  .menu--responsive {
    display: none;
  }

  .bg_dimming {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .nav {
    padding: 28px 5.4vw;

    &--logo {
      font-family: $suisse-book;
    }
  }

  .menu--responsive {
    height: auto;
    width: auto;
  }

  .menu_item-wrap {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 380px) {
  .menu_item-wrap:first-child {
    margin-top: 35%;
  }

  .menu--responsive_items li button {
    font-size: 31px;
  }
}

@media screen and (max-width: 360px) {
  .nav {
    padding: 21px 5.4vw;
  }
}

@media screen and (max-width: 340px) {
  .menu--responsive_items {
    height: 60%;
  }
  .menu--responsive_items li button {
    font-size: 28px;
  }
}

@media screen and (max-height: 568px) {
  .menu--responsive_items {
    height: 60%;
  }
}
