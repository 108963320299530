/*
======================
Hero
======================
*/
.hero {
  height: 100%;
  align-items: center;
  user-select: none;
  min-height: 580px;

  &_container {
    display: flex;
    // height: 100%;
    height: 100vh;
    // max-height: 100vh;
    min-height: 600px;
    justify-content: flex-start;
    align-items: center;
  }

  .w-text {
    overflow: hidden;
    white-space: nowrap;
  }

  &--headline {
    z-index: 2;
    max-width: 80%;
    height: auto;
    margin-top: -2rem;
    // margin-top: calc(-200 * 100vh / var(--size));

    span.underline {
      position: relative;
      text-decoration: none;
      padding: 2px;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0.1em;
        height: 0.02em;
        border-style: solid;
        background: #000;
        border-width: 0 0 2.5px;
      }
    }
  }

  &--avatar {
    user-select: none;
    position: absolute;
    // margin-top: -4rem;
    width: 25vw;
    z-index: 1;
    right: calc(135 * 100vw / var(--size));
    // transition: 0.4s ease all;
  }

  &--outlined-box {
    position: absolute;
    border: 1px solid $black;
    top: 64%;
    right: calc(135 * 100vw / var(--size) + 5vw);
    height: 20vh;
    width: 100%;
    max-width: 22vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &_coords {
      padding-bottom: 2rem;
    }
  }

  &--contact-cta {
    position: absolute;
    top: calc(100vh - 27 * 100vw / var(--size) - 2.5vw);
    right: calc(135 * 100vw / var(--size));
    text-align: right;

    a {
      color: $black;
      text-decoration: none;
    }
  }
}

@keyframes moveHeroUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* Spinner */
.c-spinner {
  position: absolute;
  width: 10.416666666666668vw;
  width: calc(150 * 100vw / var(--size));
  left: -3.0729166666666665vw;
  left: calc(-44.25 * 100vw / var(--size));
  top: calc(100vh - 14vw);
  // bottom: calc(45 * 100vw / var(--size));

  // animation
  // animation: 1s ease 4s moveRight, 16s linear 5s spin infinite;
  animation: 16s linear 4s spin infinite;
  will-change: transform;
}

@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hero Contact CTA */
.c-wwu {
  height: 1.6667vw;
  height: calc(27 * 100vw / var(--size));
  padding-right: 0;
  font-size: 3.5vw;
  font-size: calc(14 * 100vw / var(--size));
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  text-transform: capitalize;
  pointer-events: auto;
  cursor: pointer;
  text-align: right;
  width: auto;
  min-width: max-content;
}

.c-wwu__inner {
  position: relative;
}

.c-wwu__text {
  overflow: hidden;
  line-height: 0.9;
  color: $black;
}

.c-wwu__line {
  bottom: -1.25vw;
  bottom: calc(-5 * 100vw / var(--size));
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  overflow: hidden;
  -webkit-transform-origin: left;
  transform-origin: left;
}

.c-wwu__line:after,
.c-wwu__line:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: currentColor;
  transition: -webkit-transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: transform;
}

.c-wwu__line:after {
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-delay: 0.25s;
  will-change: transform;
}

.c-wwu:hover .c-wwu__line:after,
.c-wwu__line:before {
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition-delay: 0s;
}

.c-wwu:hover .c-wwu__line:before {
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-delay: 0.25s;
}

.menu_item-wrap li button {
  outline: 0;
  border: none;
}

@media screen and (max-width: 1280px) {
  .c-wwu {
    font-size: 4vw;
    font-size: calc(18 * 100vw / var(--size)) !important;
  }

  .js-wwu__text {
    font-size: inherit !important;
  }

  .c-spinner {
    width: 12vw;
    width: calc(170 * 100vw / var(--size)) !important;
    top: calc(100vh - 15vw);
  }

  .hero--contact-cta {
    top: calc(100vh - 34 * 100vw / var(--size) - 2.5vw);
  }
}

@media screen and (max-width: 890px) {
  .hero--headline {
    max-width: 80%;
  }

  .hero--avatar {
    width: 280px;
    right: calc(135 * 100vw / var(--size));
  }

  .c-spinner {
    width: 150px !important;
    top: calc(100vh - 21vw);
  }

  .hero--contact-cta {
    top: calc(100vh - 50 * 100vw / var(--size) - 2.5vw);
  }

  .c-wwu {
    font-size: 16px !important;
  }

  .js-wwu__text {
    font-size: inherit !important;
  }
}

@media screen and (max-width: 820px) {
  .hero--headline {
    margin-top: -20vh;
    max-width: 100%;
  }

  .hero--avatar {
    margin-top: 8vh;
  }

  .hero--avatar {
    width: 280px;
    right: 9vw;
  }

  .c-spinner {
    width: 150px !important;
    top: calc(100vh - 200px);
  }

  .hero--contact-cta {
    top: calc(100vh - 8vh);
    right: 9vw;
  }
}

@media screen and (max-width: 820px) {
  .hero--avatar {
    max-width: 280px;
    width: 40vw;
    min-width: 185px;
    z-index: 1;
    right: 9vw;
  }
}

@media screen and (max-width: 580px) {
  .hero--headline {
    margin-top: -25vh;
  }

  .hero--avatar {
    margin-top: 85px;
    right: 5.4vw;
  }

  .hero--contact-cta {
    top: calc(100vh - 8vh);
    right: 5.4vw;
  }
}

@media screen and (max-width: 380px) {
  .hero--avatar {
    margin-top: 105px;
  }

  .js-wwu__text {
    font-size: 14px !important;
  }

  .c-spinner {
    width: 130px !important;
    top: calc(100vh - 170px);
  }
}

@media screen and (max-width: 740px) and (max-height: 420px) {
  .menu--responsive_items {
    width: 100vw;
    height: 100vh;
  }

  .menu_item-wrap li button {
    font-size: 25px !important;
  }

  .menu_item-wrap:first-child {
    margin-top: 0;
  }

  .menu_item-wrap:last-child {
    margin-bottom: 0;
  }

  .menu--responsive_footer {
    display: none;
  }

  .hero--avatar {
    margin-top: 180px;
  }

  .c-spinner {
    display: none;
  }

  .c-wwu {
    display: none;
  }

  .item {
    margin-bottom: 2rem;
  }

  .r-footer_c-first {
    margin-bottom: 2rem;
  }

  .footer--email {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 120px !important;
  }

  .footer .w-copyright {
    margin-bottom: 1rem;
  }
}

@media screen and (max-height: 400px) {
  .c-wwu {
    display: none;
  }
}
