/*
======================
Variables
======================
*/

// Root
:root {
  --gutter: 10vw;
  --gutter-s: calc(var(--gutter) / 2);
  --size: 400;
}

// Font Sizes

// Colors
$primary: #f12d2d;
$black: #010101;
$whitesmoke: #fafafa;
$gray: #bebebe;
$tan: #fdede4;

// Fonts
$suisse-thin: 'SuisseIntl-Thin', Helvetica, sans-serif;
$suisse-light: 'SuisseIntl-Light', Helvetica, sans-serif;
$suisse-regular: 'SuisseIntl-Regular', Helvetica, sans-serif;
$suisse-book: 'SuisseIntl-Book', Helvetica, sans-serif;
