/*
======================
Work
======================
*/

.work {
  padding-bottom: 12.055vw;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // align-items: center;

  .item:nth-child(even) {
    margin-top: 6vw;
  }
}

.item {
  max-width: 100%;
  position: relative;
  will-change: transform;
  text-decoration: none;
  color: $black;
  will-change: transform;

  &__caption-title {
    // font-family: $suisse-regular;
    margin-top: 1.6145833333333335vw;
    font-size: calc(15 * 100vw / var(--size));
    color: $black;

    &.darkMode {
      color: #fff;
    }
  }

  &__caption-copy {
    margin-top: 0.5208333333333333vw;
    font-family: $suisse-light;
    font-size: 0.8333333333333334vw;
    font-size: calc(12 * 100vw / var(--size));
    color: $black;

    &.darkMode {
      color: #d7c9c2;
    }
  }
}

.item__img-wrap {
  // --aspect-ratio: 1/1.5;
  overflow: hidden;
  min-width: 36.5vw;
  margin: 0 auto;
  padding-bottom: calc(100% / 0.6666666667);
  max-width: calc(100% - 2rem);
  will-change: transform;
}

.item__img {
  --overflow: 36px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  width: 100%;
  position: absolute;
  background-image: var(--image);
  background-size: cover;
  background-position: 50% 0%;
  will-change: transition;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.notallowed {
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed;
  }
}

.viewport {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
  will-change: transform;
}

.item__img--t1,
.item__img--t2,
.item__img--t3,
.item__img--t4,
.item__img--t5,
.item__img--t6,
.item__img--t7 {
  &:hover {
    // --overflow: 44px;
    transform: scale(1.05);
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform;
  }
}

@media screen and (max-width: 480px) {
  .item__img--t1,
  .item__img--t2,
  .item__img--t3,
  .item__img--t4,
  .item__img--t5,
  .item__img--t6,
  .item__img--t7 {
    &:hover {
      transform: scale(1);
    }
  }
}

.item:first-child .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://lh3.googleusercontent.com/fife/AGXqzDnlHk7urUKaxyMVH4uFu0DKTV5Y4HYYX0jhFTs3ZbP0Mm4fe0yq7V4Ek0mZFwnE2sUI5ko0hcmD78hHn6zfbHwbFslk09KpN2JIUvhUTTUmVyxm3jxHgxrjChYk7o_MZd3j0DAn4qytVBvlbTTmXuqCA5fGqpPyb4dyZI_CT79KrL-AhRJpjOlRBAKzI0lZiPlixhPI4Xz7Ljw7NBoumi865tcBiCfTBwDpq98AR9f4zMYZhNo0bkKYb9E0z6t1ad7BERMpbpzTA-zf4gvXQoRaG1mRWHlX2l0hRx-RF7zyQ-9X5qAc7OYKPnHfJ_y3lsDj7FdUuQuqak8cexMOz-YJnhPIbs8m1agMyf4DH8LkQMVqZira0FHT_vNuPUz9wm2GSoZ_-mR5ioUitUiyP_0NeiZDrhGjf_h3w6cjKb4-vfQ15IgWm2URF5nTR_NFC3IeEaxnhnG_I0HnhJt0trfY8c9qXpRxS-fK72mwlKuIUlfdfFPDLBXysPytyepbcAD8pKiFBcBc6-wI4RAbzCNYVEJr_CF5SiDkbkrvUThAOy8l9cgpD4NrulILgjSFxfmEQB4wBg907t4waUj-tzQFm_AsptEUEjZX7guBmk-IGwz6Eb5R2BoF6TqgiHDS9xaJMW5beWijQ4AKT6cV9rg99y7b14QKRO3pXPooRHuGd71hHG2pZSXnOz7nsyh4XnXUci65Ow6tS6tUTnCbujInsSTadNVQquZpD8Ue270LqTsHMI_9hLLApWWUBPx3WcXPiKaLHyej6jTy6GTsO0W_fJz8n5SPGm1hcVe_Nz3Qq6XHrlJk2AUKcZqdSd671aQ2pR6RONJrF2xIWj89tuisx75QdDJkhXN6oMDJ9bG_o0ru0F0KlIyxiu0e0B14Wicvn1h0LBvAMpf7Qs2GUbnjptY3zpjh92sxzSp47APb6WBkri2FllvYLDahTHFt1NbPrOFfJPEPvQNZH0FxVvS_ybh9LSusEcPziufaIe7_xUQbk23fybqkxzN1deDbTHaweafsEMV7uzqnP5ZKPs1XkhkV5ira0mMNOmqsHA9M9bIhJTYVf1WZYVc-PK9T1DiE9tvXFVi2p8EfUy2cE0lNRzFHhHkTXnqE9Clm0AACp-C3t5tNG2XMjFUxmBJwkFc00H_yX7aRW1X0ztgq6F2rjgYAyW6U1VsmBYqne-mex5bRr13UsxqQ0sDaDkA2kj1zHZJlhriZ6cTVZMnGgY2u80AVNGvA8Hyhu7efpz_5yIXcjbdv7NnO6IFKualvwZZ8VigX2R3yMwtFNM247-bgX8tsrRzKzK691dOQ5h2etyMP67r8ooU8MK21EXhLAlVFdX7MriPp1osUK5wABBsPZ10PL_5SunrdUkdptBV8VYY648jtQvuD2lqCHKr2UTx8YO7cWL5kgopHq_oNBECO68gtPKaikCC4LL6ls2w8Aohtp9JXo6J5_1JIDeKPJGMt4LZimstFXrqz3b-v-jBzW4figmN_NUkg2UMYmMLfU5tb9NONTbSPf1G5Jmx03SmVYxvdYxI=w4336-h2656);
  // --image: url(https://lh3.google.com/u/0/d/1gVAK3fv9nOyUo1SVCIeANSxjXEIz4uHf=w4608-h2274-iv1);
}

.item:nth-child(2) .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/c246f6cd41a5cc15d98fd8fc9dafceef261dd329/google.f435559d.jpg);
}

.item:nth-child(3) .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/60246980a016a98dfa9db8698935a212f92a192b/inter.4d8efbf0.jpg);
}

.item:nth-child(4) .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/59d34e7bce2d06887fce0e848700ff80bd04ad5f/rumberger.f381750b.jpg);
}

.item:nth-child(5) .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/8222d868ea40f4cc8e1eeb8a24583a7f0eaaf6d7/gibbsregister.15f496e2.jpg);
}

.item:nth-child(6) .item__img-wrap {
  --aspect-ratio: 8/11;
  --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/55560773b026dc96d3d5968ebc39758d03ff3a52/curaeskin.c91dfba0.jpg);
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .item:first-child .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://lh3.googleusercontent.com/fife/AGXqzDksALnP260lIfXCe-f6LOWxSRvFU8hiC-_PrZrMASdVMEmwSPwh3AjwBIaCThWsxI5w1ym5V9oVqcDVUhrEkWaygH27hHyRzN7EKAQ2PBHIKXWtY0i830cmFVfSa3Fw1jdZ3LxER_3Rl8eN8r0DtT-AlX5NPBWfa9Kz1awp7-gRaCE6GNpDjs72vcAY_zdyiMFNuHRcDu-LsMbrmwSO1JdBqqDvU5fMavnWdCxCYQaNHQ-w88alYcYd0ivA8U5XR2jK42a-aEKWpg3BX5Jm0FNJHoM4gB-_pDePXN2B6RscSyk0T0gzplW6_hmuVetGbMg7GCf7EZkCimZm2RvIIqj508EJu1t9PCOa2wqW0536dGzHvwrL9AttG69y61xwDB3wO6At4qumqyVphBCMjpZi6Jn-Sux-PkL6fGC_n-YElaaZ8qu9ZeJAle12aHWCuFgZssSnFhE2h0L_QxJoCEdmHCR2qbIlMCGjwu5aw-Xb0a1SiYmq3RoKGWGekNUDaFCbUShMPvyF3TnBo4eVaBV2pmhec2NtPz9SiH3qYfwUTkGRcswP005xbBDroaORKvD7HjRBCPPVBabiR9Y-6cy_OcCnFlGpMPwkQ6hpFjOULHIgSpmyCEe10iqHr40JwUxCA-cS50zX8ZJthvfySxFNba3gDsNPB1Y1YYnTytvYuPaJ9cTNUiAjGGRlR34Qcuq2-TP8KnjW3o7W8vcbuAr-yw0gHdfZpX3rK_PZm-z6_WzqgtZVRmvvLd4tbXm6UxfSDWJncj_ab8BvmDKYFT7dbelrBh7sH4TieMHRV-q49hrH2213ePtI99OuLnf3IDvL1xEEFYE7bkoXpgRIrSR-onFyonZHooplzrwWX9HKm8ho8ieYG82u2pc2OIA95EgvEUpk74SM8kR6WMyoNWksCm_EtFZz7dCAOQRjs2HDJFfg4FBxTbqOiRUXxgD-Fk5GGJ6fl8n7yS1wdhseWGUlfsuxwtCuCZH8sD7WhxR-6jSYz9_CSTgaQt9a5Km_OFU1d_hqkOaD0Xkk_PmcA7FSNbDMm0Qzr60uI_T_dRoePxTa7kyFCLYSxzWWc04vc8IM0tTHPoVGqE8MfsyPME1yvaLpcLReuvyH1LwE711twLDLq9v0am8asSQ_MD0W0at7c2AM6ypdMtaa8nyr3LdPf1dpZbiwdimRbGVOrzbR1n9CrXIbGUQIBsjXQgELv1JdWIV22MFEjU1aFyWpIRAri6UQoBGj8vtAz1tnEG4YdHV1T5nOnzNIA5veoT9A62vaYsdxz4-rDKoQ39Nv6siz5ZC4hw1qCdDlL0AzRL3SJll3lqIFTUAYYY1vBMqOs4IaYze18_6Dt5yA6g2jQa78Tlhr-dMBE1uGlQMw0GnRGqeKg4cLK8ep9uZIvDmpKVyiPAA5CB0euYasOqG8UV4OVZIZ0AwcuvsB0P-fs5qcM8gVaj30zfM6yoAWshh6U_WF3QRsAoxLYO2astEh_gVCiPYOVDslluyqHjulp-DpnGVLnoxSLFWmxGMH4NDBQyLuTVb5Evg=w5120-h2656);
  }

  .item:nth-child(2) .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/edb3cf1ce7731a7cd9de6743f1423b9a0931720f/google@2x.50c06223.jpg);
  }

  .item:nth-child(3) .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/94712733f3261518d694f066171275a2e9b721d9/inter@2x.79eadb06.jpg);
  }

  .item:nth-child(4) .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/cd51648c47831906357537add75d8045d4672bde/rumberger@2x.1b2b165b.jpg);
  }

  .item:nth-child(5) .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/c22395847925fd9b5415d2ec55b29dbb91087c27/gibbsregister@2x.dfec309c.jpg);
  }

  .item:nth-child(6) .item__img-wrap {
    --aspect-ratio: 8/11;
    --image: url(https://d33wubrfki0l68.cloudfront.net/static/media/5b113f38728b21fd800fdf4dae3ff2686f9db866/curaeskin@2x.0194b247.jpg);
  }
}

@media screen and (max-width: 1280px) {
  .work .row {
    margin-top: 4rem;
  }

  .item {
    &__caption {
      max-width: calc(100% - 2rem);
    }

    &__caption-title {
      margin-top: 2.25vw;
      font-size: calc(19 * 100vw / var(--size));
    }

    &__caption-copy {
      margin-top: 0.8vw;
      font-size: calc(16 * 100vw / var(--size));
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 890px) {
  .item {
    margin-bottom: 4rem;

    &__caption-title {
      margin-top: 28px;
      font-size: 16px;
    }

    &__caption-copy {
      margin-top: 8px;
      font-size: 14px;
      height: 40px;

      max-width: 280px;
      word-wrap: break-word;
    }
  }

  .item__img-wrap {
    height: 440px;
    max-height: 440px;
    min-height: 440px;
    min-width: 36.5vw;
    margin: 0;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    max-width: calc(100% - 2rem);
  }

  .item__img {
    height: 440px !important;
    max-height: 440px !important;
    padding-bottom: 0;
    top: 0;
    width: 100%;
    background-image: var(--image);
  }

  .item:first-child .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(2) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(3) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(4) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(5) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(6) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(7) .item__img-wrap {
    --aspect-ratio: 0;
  }
}

@media screen and (max-width: 767px) {
  .work .row {
    flex-direction: column;

    .item:nth-child(even) {
      margin-top: 0;
    }
  }

  .item {
    margin: 0 auto 6.5rem;
    width: 100% !important;

    &__caption-title {
      margin-top: 28px;
      font-size: 16px;
    }

    &__caption-copy {
      margin-top: 8px;
      font-size: 14px;
      height: 40px;
    }
  }

  .item__img-wrap {
    height: 65vh;
    max-height: 65vh;
    min-height: 540px;
    padding-bottom: 0;
    max-width: 100%;
  }

  .item__img {
    height: 65vh !important;
    max-height: 65vh !important;
    min-height: 540px;
    padding-bottom: 0;
    top: 0;
    width: 100%;
    background-image: var(--image);
  }

  .item:first-child .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(2) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(3) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(4) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(5) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(6) .item__img-wrap {
    --aspect-ratio: 0;
  }

  .item:nth-child(7) .item__img-wrap {
    --aspect-ratio: 0;
  }
}

@media screen and (max-width: 380px) {
  .item {
    margin: 0 auto 3.85rem;
  }
}

.lazyPortfolio {
  display: none;
}
